let defaultGraphOpts = {
            graphLabels: [],
            graphData: [],
            graphColors: [],
            graphBorderColors: [],
            graphTitle: '',
            graphStep: 100
        };

export function generate3dMarkOpts(graphData, title, version, type) {
    let graphOpts = JSON.parse(JSON.stringify(defaultGraphOpts));
    graphOpts.graphTitle = title;
    graphOpts.graphStep = 500;
    for (let i = 0; i < graphData.length; i++) {
        if (graphData[i].tdmark_result.length === 0) {
            return false
        }
        for (let b = 0; b < graphData[i].tdmark_result.length; b++) {
            if (graphData[i].tdmark_result[b].version === version) {
                let score = graphData[i].tdmark_result[b][type];
                // Check if we should be appending to the existing data or prepending, depending on if this value is highest or lowest
                let append = true;
                if (score >= graphOpts.graphData[0]) {
                    append = false;
                }

                if (append) {
                    graphOpts.graphLabels.push(graphData[i].profile.gpu[0].model);
                    graphOpts.graphData.push(score);
                    graphOpts.graphColors.push('rgba(54, 162, 235, 0.2)');
                    graphOpts.graphBorderColors.push('rgba(54, 162, 235, 1)');
                } else {
                    graphOpts.graphLabels.unshift(graphData[i].profile.gpu[0].model);
                    graphOpts.graphData.unshift(score);
                    graphOpts.graphColors.unshift('rgba(54, 162, 235, 0.2)');
                    graphOpts.graphBorderColors.unshift('rgba(54, 162, 235, 1)');
                }
            }
        }
    }
    let min = Math.floor(Math.min.apply(Math, graphOpts.graphData) / 100) * 100;
    let max = Math.ceil(Math.max.apply(Math, graphOpts.graphData) / 100) * 100;
    min = min - 1500;
    if (min < 0) {
        min = 0
    }
    graphOpts.scaleMin = min;
    graphOpts.scaleMax = max + 1000;
    return graphOpts
}


export function generateLineGraph(graphOpts) {

    let data = {
        type: 'line',
        data: {
            labels: graphOpts.graphLabels,
            datasets: []
        },
        weight: 1000,

        options: {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    enabled: false,
                    display: false
                },
                title: {
                    display: true,
                    color: '#babcbd',
                    text: graphOpts.graphTitle
                },
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
            },
            indexAxis: 'x',
            events: [],  // Disables hover
            legend: {
                display: false
            },
            hover: {
                mode: 'dataset',
                animationDuration: 0
            },
            tooltips: {
                enabled: false
            },
            scales: {
                y: {
                    title: {text: "FPS", display: true, color: '#babcbd'},
                    min: graphOpts.scaleMin,
                    stepSize: graphOpts.graphStep,
                    ticks: {
                        color: '#babcbd'
                    },
                    grid: {
                        color: '#404040'
                    }
                },
                x: {
                    title: {text: "Seconds", display: true, color: '#babcbd'},
                    ticks: {
                        stepSize: graphOpts.graphStep,
                        min: graphOpts.scaleMin,
                        max: graphOpts.scaleMax,
                        color: '#babcbd'
                    },
                    grid: {
                        color: '#404040'
                    }
                }
                // xAxes: [{
                //     //type: 'logarithmic',
                //     //position: 'bottom',
                //     barPercentage: 1.0,
                //     gridLines: {
                //         color: '#202124',
                //         offsetGridLines: false
                //     },
                //     ticks: {
                //         stepSize: graphOpts.graphStep,
                //         min: graphOpts.scaleMin,
                //         max: graphOpts.scaleMax
                //     }
                // }]
            },
            animation: {}

        }
    };
    if (graphOpts.graphData) {
        let dataset = {
            data: graphOpts.graphData,
            backgroundColor: graphOpts.graphColors,
            borderColor: graphOpts.graphBorderColors,
            pointBackgroundColor: graphOpts.graphColors,
            pointBorderColor: 'rgba(54, 162, 235)',
            hoverBackgroundColor: graphOpts.graphBorderColors,
            hoverBorderColor: graphOpts.graphColors,
            borderWidth: 3,
            tension: 0.3
        };
        data.data.datasets.push(dataset)
    }
    return {...data}
}
export function generateBarGraph(graphOptsWrap) {
    let avgGraphOpts = graphOptsWrap[0]
    let minGraphOpts = graphOptsWrap[1]
    let maxGraphOpts = graphOptsWrap[2]
    let annotations = {}
    for (let i = 0; i < avgGraphOpts.graphData.length; i++) {
        annotations[`line${i+1}]`] = {
            type: 'line',
            borderColor: 'green',
            borderWidth: 3,
            label: {
                display: true,
                backgroundColor: 'green',
                borderRadius: 0,
                color: 'white',
                // content: (ctx) => middleValue(ctx, 0, 0.5)
                content: ""
            },
            yMax: indexToMax(0) + 0.05,
            yMin: indexToMin(0) - 0.05,
            xScaleID: 'x',
            xMax: avgGraphOpts.graphData[i],
            xMin: avgGraphOpts.graphData[i],
            yScaleID: 'y'
        }
    }
    let data = {
        type: 'bar',
        data: {
            labels: avgGraphOpts.buildLabels,
            datasets: []
        },
        weight: 1000,

        options: {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                annotation: {
                    annotations: annotations

                },
                datalabels: {
                    color: 'white',
                    align: 'left',
                    anchor: 'end',
                    clamp: true,
                    font: {
                        weight: 'bold'
                    },
                },
                title: {
                    // Benchmark type name
                    display: true,
                    color: '#babcbd',
                    text: avgGraphOpts.graphTitle
                },
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: 'legend-container',
                },
                legend: {
                    display: true,
                }
            },
            indexAxis: 'y',
            events: ['click'],  // Disables hover
            legend: {
            },
            hover: {
                mode: 'dataset',
                animationDuration: 0
            },
            tooltips: {
                enabled: false
            },
            scales: {
                y: {},
                x: {
                    stacked: false,
                    min: avgGraphOpts.scaleMin,
                    max: avgGraphOpts.scaleMax,
                }
            },
            animation: {}

        }
    };
    let minDataset = {
        data: minGraphOpts.graphData,
        label: minGraphOpts.graphLabels,
        backgroundColor: minGraphOpts.graphColors,
        borderColor: minGraphOpts.graphBorderColors,
        // hoverBackgroundColor: minGraphOpts.graphBorderColors,
        // hoverBorderColor: minGraphOpts.graphColors,
        borderWidth: 1,
        grouped:false,
    };
    data.data.datasets.push(minDataset)
    let avgDataset = {
        data: avgGraphOpts.graphData,
        label: avgGraphOpts.graphLabels,
        backgroundColor: avgGraphOpts.graphColors,
        borderColor: avgGraphOpts.graphBorderColors,
        // hoverBackgroundColor: avgGraphOpts.graphBorderColors,
        // hoverBorderColor: avgGraphOpts.graphColors,
        borderWidth: 1,
            // right:1
        // },
        grouped:false,
    };
    data.data.datasets.push(avgDataset)
    let maxDataset = {
        data: maxGraphOpts.graphData,
        label: maxGraphOpts.graphLabels,
        backgroundColor: maxGraphOpts.graphColors,
        borderColor: maxGraphOpts.graphBorderColors,
        // hoverBackgroundColor: maxGraphOpts.graphBorderColors,
        // hoverBorderColor: maxGraphOpts.graphColors,
        borderWidth: 1,
        grouped:false,
    };
    data.data.datasets.push(maxDataset)


    console.log(data)
    return {...data}
}

function indexToMin(index) {
  return index - 0.31;
}

function indexToMax(index) {
  return index + 0.31;
}

function middleValue(ctx, index, perc) {
  const chart = ctx.chart;
  const dataset = chart.data.datasets[0];
  return dataset.data[index];
}
