export class BuildProfile {
    rid: any
    id: any
    cpu: any
    cinebench_score: any
    source: string
    sourceVersion: string
    benchmarks = []
    gpu: IGPU[] = []
    memory: IMemory[] = []
    images = []
    title: any
    uid: any
    isPrivate: boolean;
    mem_total: any
    cpu_id: any
    model: any;
    freq: any;
    user_id: any;
    user: any;
    compareBarHidden: boolean = false;

    constructor(benchmarkData) {
        this.rid = benchmarkData.id;
        this.id = benchmarkData.id;
        this.cpu = benchmarkData.cpu;
        this.model = benchmarkData.cpu.model;
        this.freq = benchmarkData.freq;
        this.title = benchmarkData.title;
        this.cpu_id = benchmarkData.cpu.cpu_id;
        this.user_id = benchmarkData.user_id;
        this.user = benchmarkData.user;
        this.source = benchmarkData.source;
        this.sourceVersion = benchmarkData.source_version;
        this.images =[]
        for (let i = 0; i < benchmarkData.images.length; i++) {
            this.images.push({
                src: benchmarkData.images[i].url,
                path: benchmarkData.images[i].url,
                caption: '',
                thumb: benchmarkData.images[i].url
            })
        }
        this.mem_total = 0;
        this.isPrivate = benchmarkData.private;
        for(let i=0;i<benchmarkData.benchmarks.length;i++){
            let score_total = 0;
            let scores = []
            for(let r=0;r<benchmarkData.benchmarks[i].runs.length;r++) {
                score_total += benchmarkData.benchmarks[i].runs[r].score
                scores.push(benchmarkData.benchmarks[i].runs[r].score)
            }
            benchmarkData.benchmarks[i].averageScore = score_total / scores.length
            let graphOpts = {
                graphLabels: [],
                graphData: [],
                graphColors: ['rgba(54, 162, 235, 0.2)'],
                graphBorderColors: ['rgba(54, 162, 235, 0.2)'],
                graphTitle: 'Avg FPS',
                graphStep: 10,
                scaleMin:0
            };
            if(benchmarkData.benchmarks[i].runs[0]['fps_buckets'] != null) {
                let index = 0
                for (const second in benchmarkData.benchmarks[i].runs[0]['fps_buckets']) {
                    index++;
                    let second_data = benchmarkData.benchmarks[i].runs[0]['fps_buckets'][second]
                    graphOpts.graphData.push(second_data['avg_fps'])
                    graphOpts.graphLabels.push(index)
                }
                let cb_min = Math.floor(Math.min.apply(Math, graphOpts.graphData) / 1.25);
                if (cb_min < 0) {
                    cb_min = 0
                }
                graphOpts.scaleMin = cb_min;
                benchmarkData.benchmarks[i].graphOpts = this.generateGraph(graphOpts)
            }

        }
        this.benchmarks = benchmarkData.benchmarks
        try {
            for (let g = 0; g < benchmarkData.gpu.length; g++) {
                this.gpu.push(benchmarkData.gpu[g])
            }
        } catch (e) {

        }
        for (let r = 0; r < benchmarkData.memory.length; r++) {
            this.memory.push(benchmarkData.memory[r])
            this.mem_total += Number(benchmarkData.memory[r].gigabytes)
        }
        this.freq = benchmarkData.freq;
    }
    generateGraph(graphOpts) {

        let data = {
            type: 'line',
            data: {
                labels: graphOpts.graphLabels,
                datasets: []
            },
            weight: 1000,

            options: {
                responsive: false,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        enabled:false,
                        display:false
                      },
                    title: {
                        display: true,
                         color: '#babcbd',
                        text: graphOpts.graphTitle
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                },
                indexAxis: 'x',
                events: [],  // Disables hover
                legend: {
                    display: false
                },
                hover: {
                    mode: 'dataset',
                    animationDuration: 0
                },
                tooltips: {
                    enabled: false
                },
                scales: {
                    y:{
                        title:{text:"FPS", display:true, color: '#babcbd'},
                        min: graphOpts.scaleMin,
                        stepSize: graphOpts.graphStep,
                        ticks:{
                            color: '#babcbd'
                        },
                        grid:{
                            color: '#404040'
                        }
                    },
                    x:{
                        title:{text:"Seconds", display:true, color: '#babcbd'},
                        ticks: {
                            stepSize: graphOpts.graphStep,
                            min: graphOpts.scaleMin,
                            max: graphOpts.scaleMax,
                            color: '#babcbd'
                        },
                        grid:{
                            color: '#404040'
                        }
                    }
                    // xAxes: [{
                    //     //type: 'logarithmic',
                    //     //position: 'bottom',
                    //     barPercentage: 1.0,
                    //     gridLines: {
                    //         color: '#202124',
                    //         offsetGridLines: false
                    //     },
                    //     ticks: {
                    //         stepSize: graphOpts.graphStep,
                    //         min: graphOpts.scaleMin,
                    //         max: graphOpts.scaleMax
                    //     }
                    // }]
                },
                animation: {

                }

            }
        };
        if (graphOpts.graphData) {
            let dataset = {
                data: graphOpts.graphData,
                backgroundColor: graphOpts.graphColors,
                borderColor: graphOpts.graphBorderColors,
                pointBackgroundColor: graphOpts.graphColors,
                pointBorderColor: 'rgba(54, 162, 235)',
                hoverBackgroundColor: graphOpts.graphBorderColors,
                hoverBorderColor: graphOpts.graphColors,
                borderWidth: 3,
                tension: 0.3
            };
            data.data.datasets.push(dataset)
        }
        return {...data}
    }
}

export interface IGPU {
    make: any
    model: any
}

export interface IMemory {
    make: any
    model: any
    modelname: any
    speed: any
    gigabytes:any
}
