<div *ngIf="game">
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">{{game.title}}</h3>
        </div>
    </div>
    <div style="display: flex;">
        <canvas class="graph"
                [type]="fpsTimeGraphOpts.type"
                [datasets]="fpsTimeGraphOpts.data.datasets"
                [labels]="fpsTimeGraphOpts.data.labels"
                [options]="fpsTimeGraphOpts.options"
                baseChart>
        </canvas>
        <canvas class="graph"
                [type]="fpsAvgGraphOpts.type"
                [datasets]="fpsAvgGraphOpts.data.datasets"
                [labels]="fpsAvgGraphOpts.data.labels"
                [options]="fpsAvgGraphOpts.options"
                baseChart>
        </canvas>
    </div>
</div>
