
<div class="row">
    <div class="col-lg-12">
        <h1 class="centered" style="text-align: center;"><img priority ngSrc="https://r2.comparebench.com/assets/logo_footer.webp" height="84" width="350"></h1>
        <h4 style="text-align: center;font-family: 'Raleway',sans-serif;font-weight: 300;color: #babcbd;">Track your benchmarks and make comparisons</h4>
        <hr class="hr-landing">
    </div>
    <hr class="hr-landing">
    <div class="col-lg-6">
        <h3 style="text-align: center;color:#007cb6">A New Platform</h3>
        <p>
            We’re developing a new comprehensive PC benchmarking platform where you can upload your results,
            compare them with our official benchmarks, or see how they stack up against the broader community.</p>
        <p>
            Once your results are imported, you can share your entire build profile that spans multiple results with one link. </p>

        <div style="display: flex;justify-content: center">
            <a routerLink="/login">
                <button mat-raised-button [color]="'primary'">Start Benchmarking</button>
            </a>
        </div>
    </div><!-- col-lg-6 -->

    <div class="col-lg-6">
        <img class="img-thumbnail hero-subimage" width="600" height="340" ngSrc="https://r2.comparebench.com/assets/benchmarks1.webp" priority>
    </div>

    <div class="col-lg-6">
        <hr class="hr-landing">
        <img class="img-thumbnail hero-subimage" src="https://r2.comparebench.com/assets/comparison.png">
    </div><!-- col-lg-6 -->

    <div class="col-lg-6">
        <hr class="hr-landing">
        <h3 style="text-align: center;color:#007cb6">Detailed Graphs</h3>
        <p>
            Create comparisons from within your benchmarks, or compare your benchmarks with others.</p>
        <p>
            Compare everything from CPU frequency to storage speed; see how your build stacks up.
        </p>
        <p>Supported Benchmarks (with more on the way):</p>
        <div class="supportedApps">
            <div class="supportedAppsIcons">
                <img ngSrc="https://comparebench.com/cdn-cgi/image/quality=80,fit=scale-down,format=webp,height=40/https://r2.comparebench.com/cinebench.png" width="40px" height="40px">
                <p>Cinebench</p>
            </div>
            <div class="supportedAppsIcons">
                <img ngSrc="/assets/icons/3dmark-icon.png" width="43px" height="40px">
                <p>3DMark</p>
            </div>
        </div>
        <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
            <p>Join our Discord</p>
            <a href="https://discord.gg/V3nRgYA">
                <img src="assets/discord.png">
            </a>
        </div>
    </div><!-- col-lg-6 -->


    <div class="col-lg-12" *ngIf="recents">
        <hr class="hr-landing">
        <div>
            <h3>Recent Results</h3>
            <div class="row" style="padding-left: 15px;display: flex; flex-direction: row;gap: 10px">
                <mat-card *ngFor="let build of recents">
                  <mat-card-title-group>
                      <mat-card-subtitle style="margin-bottom: 5px;">
                          <a routerLink="/profile/{{build.user_id}}">
                            {{build.user}}
                          </a>
                      </mat-card-subtitle>
                    <mat-card-title>
                        <a routerLink="/build/{{build.id}}">
                            {{build.title}}
                          </a>
                    </mat-card-title>

                      <mat-card-subtitle style="margin-bottom: 5px;">
                          CPU: {{build.cpu.make}} {{build.cpu.model}}
                      </mat-card-subtitle>
                      <mat-card-subtitle style="margin-bottom: 5px;">
                          GPU: {{build.gpu[0].make}} {{build.gpu[0].model}}
                      </mat-card-subtitle>
                  </mat-card-title-group>
                  <mat-card-content>
                      <div style="display: flex;align-items: center;">
<!--                          <p style="margin: 0">{{build.cpu.make}} {{build.cpu.model}}</p>-->
<!--                      <img *ngIf="benchmark.benchmark_type == 15" style="height: 40px;" src="/assets/icons/cinebench.png" >-->
                          </div>

                  </mat-card-content>
                </mat-card>
            </div>
        </div>

        <hr class="hr-landing">
    </div>
    <div class="col-lg-12" style="display: flex;justify-content: center;padding-top: 25px;">
        <h3>Yardstick</h3>
    </div>
    <div class="col-lg-6">
        <hr class="hr-landing">
        <p>
            We're also developing a new desktop application that gathers your system's hardware
            details so you can easily upload them to CompareBench.</p>
        <p>
            Use Yardstick to keep track of your computer's performance when you make changes.
            New GPU installed? Run another benchmark and compare the difference. </p>
        <div style="display:flex;justify-content: center">
            <button [color]="'primary'" mat-flat-button routerLink="/yardstick">Learn More</button>
        </div>
    </div>

    <div class="col-lg-6">
        <hr class="hr-landing">
        <div style="display: flex;justify-content: center;">
            <img class="img-thumbnail" src="/assets/yardstick-app1.png">
        </div>
    </div><!-- col-lg-6 -->

</div><!-- row -->
