import { Component, OnInit } from '@angular/core';
import {GamePerformanceService} from "../game.service";
import {ActivatedRoute} from "@angular/router";
import {generateBarGraph, generateLineGraph} from "../graphs/graphs";
import {Chart} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-game-performance',
  templateUrl: './game-performance.component.html',
  styleUrls: ['./game-performance.component.css']
})
export class GamePerformanceComponent implements OnInit {
    game;
    fpsTimeGraphOpts;
    fpsAvgGraphOpts;
    constructor(
        private route: ActivatedRoute,
        private gameService: GamePerformanceService
    ) {
    }

    ngOnInit(): void {
        Chart.register(annotationPlugin);
        this.gameService.getGame(this.route.snapshot.paramMap.get('gameId')).subscribe(response => {

            this.game = response;
            for (let i = 0; i < this.game.builds.length; i++) {
                let build = this.game.builds[i];
                this.fpsTimeGraphOpts = generateLineGraph(this.generateFpsTimeGraph(build))
                this.fpsAvgGraphOpts = generateBarGraph(this.generateAvgFpsGraph())
            }
        })
    }
    generateAvgFpsGraph() {
        let graphOptsWrap = []
        let avgFpsGraphOpts = {
            buildLabels: [],
            graphLabels: ["Avg FPS"],
            graphData: [],
            graphColors: [],
            graphBorderColors: [],
            graphTitle: '',
            graphStep: 100
        };
        let minFpsGraphOpts = {
            buildLabels: [],
            graphLabels: ["Min FPS"],
            graphData: [],
            graphColors: [],
            graphBorderColors: [],
            graphTitle: '',
            graphStep: 100
        };
        let maxFpsGraphOpts = {
            buildLabels: [],
            graphLabels: ["Max FPS"],
            graphData: [],
            graphColors: [],
            graphBorderColors: [],
            graphTitle: '',
            graphStep: 100
        };
        Chart.defaults.color = "#babcbd";
        Chart.register(ChartDataLabels);
        for( let i = 0; i < this.game.builds.length; i++ ) {
            let build = this.game.builds[i]
            avgFpsGraphOpts.buildLabels.push(this.game.builds[i].cpu.model);
            for (let b = 0; b < build.benchmarks.length; b++) {
                if (build.benchmarks[b].benchmark_type == this.game.benchmark_type) {
                    console.log(build.benchmarks[b].benchmark_type_name)
                    // Within this benchmark, pull out data from all runs
                    let allAvgs = []
                    let allMins = []
                    let allMaxes = []
                    let avgTotal = 0
                    let minTotal = 0
                    let maxTotal = 0

                    for (let runIndex = 0; runIndex < build['benchmarks'][b]['runs'].length; runIndex++) {
                        allAvgs.push(build['benchmarks'][b]['runs'][runIndex].score)
                        avgTotal += build['benchmarks'][b]['runs'][runIndex].score

                        allMins.push(build['benchmarks'][b]['runs'][runIndex].ten_percent_low_fps)
                        minTotal += build['benchmarks'][b]['runs'][runIndex].ten_percent_low_fps

                        allMaxes.push(build['benchmarks'][b]['runs'][runIndex].max_fps)
                        maxTotal += build['benchmarks'][b]['runs'][runIndex].max_fps
                    }
                    avgFpsGraphOpts.graphData.push([Math.round((avgTotal / allAvgs.length))]);
                    minFpsGraphOpts.graphData.push([Math.round((minTotal/ allMins.length))]);
                    console.log(Math.round((avgTotal / allAvgs.length) * 100) / 100)
                    maxFpsGraphOpts.graphData.push([Math.round((maxTotal / allMaxes.length))]);
                }

            }
            // Min fps
            // "#37a2eb"
            minFpsGraphOpts.graphColors.push("#273b4d");
            minFpsGraphOpts.graphBorderColors.push('#37a2eb');
            // Avg fps
            avgFpsGraphOpts.graphColors.push("#273b4d");
            avgFpsGraphOpts.graphBorderColors.push(
                '#37a2eb');
            // Max FPS
            maxFpsGraphOpts.graphColors.push("#273b4d");
            maxFpsGraphOpts.graphBorderColors.push('#37a2eb');
        }
        graphOptsWrap.push(avgFpsGraphOpts);
        graphOptsWrap.push(minFpsGraphOpts);
        graphOptsWrap.push(maxFpsGraphOpts);
        return graphOptsWrap
    }

    generateFpsTimeGraph(build){
        let fpsGraphOpts = {
            graphLabels: [],
            graphData: [],
            graphColors: ['rgba(54, 162, 235, 0.2)'],
            graphBorderColors: ['rgba(54, 162, 235, 0.2)'],
            graphTitle: 'Avg FPS',
            graphStep: 10,
            scaleMin: 0,
            scaleMax: 0,
        };
        let graphLabel = build.cpu.model
        fpsGraphOpts.graphLabels.push(graphLabel);
        for(let i = 0; i < build.benchmarks.length; i++) {
            if (build.benchmarks[i].runs[0]['fps_buckets'] != null) {
                let index = 0
                for (const second in build.benchmarks[i].runs[0]['fps_buckets']) {
                    index++;
                    let second_data = build.benchmarks[i].runs[0]['fps_buckets'][second]
                    fpsGraphOpts.graphData.push(second_data['avg_fps'])
                    fpsGraphOpts.graphLabels.push(index)
                }
                let cb_min = Math.floor(Math.min.apply(Math, fpsGraphOpts.graphData) / 1.25);
                let cb_max = Math.ceil(Math.max.apply(Math, fpsGraphOpts.graphData) / 100) * 100;
                if (cb_min < 0) {
                    cb_min = 0
                }
                fpsGraphOpts.scaleMin = cb_min;
                fpsGraphOpts.scaleMax = cb_max;
            }
        }
        fpsGraphOpts.graphColors.push('rgba(54, 162, 235, 0.2)');
        fpsGraphOpts.graphBorderColors.push('rgba(54, 162, 235, 1)');
        return fpsGraphOpts
    }
}
