
export interface IResponse {
    bench_results: any;
    comparisons: any;
    results_count: any;
    compare_count: any;
    benchmarks:any;
	any
}
export interface ILoginResponse {
    code: any
    message: any
	display_name: any
    email: any
    id: any
}

export class User {
    displayName: string
    id: number
    constructor(jsonData){
        this.displayName = jsonData.display_name
        this.id = jsonData.id
    }
}
export class IGames {
    image_url: string
    id: number
    constructor(jsonData){
        this.image_url = jsonData.image_url
        this.id = jsonData.id
    }
}
