import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import posthog from "posthog-js";

if (environment.production) {
    enableProdMode();
}
posthog.init(
    "phc_wIuEXavFHzY9z3slA3M66xq4VtDHapiQnSTsNOnjaE7",
    {
        api_host: "https://us.i.posthog.com",
        person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
        capture_pageview: false,
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true
            }
        }
    }
)

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
