import { Component, OnInit } from '@angular/core';
import {GamePerformanceService} from "../game.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-game-performance-list',
  templateUrl: './game-performance-list.component.html',
  styleUrls: ['./game-performance-list.component.css']
})
export class GamePerformanceListComponent implements OnInit {
    search;
    searchUpdate = new Subject<string>();
    pageSize = 20;
    pageSizeOptions: number[] = [10, 20, 50, 100];
    pageIndex = 0
    games;
    constructor(private gameService: GamePerformanceService) {
        this.searchUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(value => {
                this.pageIndex = 0
                this.refreshList();
            });
    }
    refreshList() {
        this.gameService.getGames(this.search).subscribe(response => {
            this.games = response;
        })
    }
    ngOnInit(): void {
        this.refreshList();
    }

}
