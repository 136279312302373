import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../user.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {WINDOW} from '../../window';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
    email;
    password;
    errorMessage;
    signupForm = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
    });
    constructor(@Inject(WINDOW) private window: Window, private userService: UserService,
                private formBuilder: UntypedFormBuilder, private router: Router) {
    }

    discord() {
        this.window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=854808456828551189&redirect_uri=https%3A%2F%2Fcomparebench.com%2Fapi%2Foauth%2Fdiscord&response_type=code&scope=identify%20email';
    }

    signup() {
        this.userService.signup(this.signupForm.value.email, this.signupForm.value.password).subscribe((response) => {
            if (response.code != 403) {
                this.userService.loggedIn.next(true);
                this.router.navigate(['/dashboard']);
            }
            else{
                this.errorMessage = response.message;
            }
        });
    }

    ngOnInit() {
    }

}
