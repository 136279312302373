import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserService} from "./user.service";
import {Observable} from "rxjs";
import {IGames} from "./interfaces/response";

@Injectable({
  providedIn: 'root'
})
export class GamePerformanceService {

    constructor(private http: HttpClient) {}

    _gamesUrl: string = UserService.getHostname() + "/games";
    _gameUrl: string = UserService.getHostname() + "/games";

    getGames(search): Observable<IGames> {
        let url = this._gamesUrl
        if(search){
            url = `${this._gamesUrl}?search=${search}`;
        }
        return this.http.get<IGames>(url, {})
    }
    getGame(gameId): Observable<IGames> {
        return this.http.get<IGames>(`${this._gameUrl}/${gameId}`, {})
    }
}
