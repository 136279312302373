import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import { WINDOW_PROVIDERS } from "./window";
import {TokenInterceptor} from "./token.interceptor";
import {CookieService} from "ngx-cookie-service";
import { BenchmarkCreationComponent } from './benchmark-creation/benchmark-creation.component';
import { BenchmarkProfileComponent } from './benchmark-profile/benchmark-profile.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LoginComponent } from './auth/login/login.component';
import {SignupComponent} from "./auth/signup/signup.component";
import { RoadmapComponent } from './roadmap/roadmap.component';
import { ComparisonCreationComponent } from './comparison-creation/comparison-creation.component';
import { ResultsComponent } from './results/results.component';
import { CompareBarComponent } from './compare-bar/compare-bar.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DndDirective} from "./dnd.directive";
import { ProfilePhotoUploadComponent } from './profile-photo-upload/profile-photo-upload.component';
import {IvyCarouselModule} from "../assets/ivyсarousel_pro/carousel.module";
import {LightboxModule} from "ngx-lightbox";
import { AdminCreateProductComponent } from './admin-create-product/admin-create-product.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { YardstickLandingComponent } from './yardstick-landing/yardstick-landing.component';
import { FaqComponent } from './faq/faq.component';

import { FooterComponent } from './footer/footer.component';
import {SharedModule} from "./shared/shared.module";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import { GamePerformanceComponent } from './game-performance/game-performance.component';
import { GamePerformanceListComponent } from './game-performance-list/game-performance-list.component';
import {NgChartsModule} from "ng2-charts";

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        BenchmarkCreationComponent,
        BenchmarkProfileComponent,
        NavigationComponent,
        LoginComponent,
        SignupComponent,
        RoadmapComponent,
        ComparisonCreationComponent,
        ResultsComponent,
        CompareBarComponent,
        DndDirective,
        ProfilePhotoUploadComponent,
        AdminCreateProductComponent,
        PageNotFoundComponent,
        YardstickLandingComponent,
        FaqComponent,
        FooterComponent,
        GamePerformanceListComponent,
        GamePerformanceComponent,
    ],
    imports: [
        AppRoutingModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxGoogleAnalyticsModule.forRoot('G-6FHEZF3PW6'),
        NgxGoogleAnalyticsRouterModule,
        // TODO: Move ivycarousel to whatever component needs it instead of shared
        IvyCarouselModule,
        LightboxModule,
        NgChartsModule,
    ],
    providers: [
        CookieService,
        WINDOW_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {

    }
}


