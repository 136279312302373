<div style="display: flex;justify-content: center">
    <div style="flex-grow: 1">
        <mat-form-field appearance="fill">
            <mat-label style="color: #babcbd;">Search</mat-label>
            <input matInput [(ngModel)]="search" (ngModelChange)="this.searchUpdate.next($event)">
          </mat-form-field>
    </div>
    <div style="flex-grow: 10">

        <div style="display: flex" *ngFor="let game of games">
            <a routerLink="/games/{{game.id}}">
                <div>
                    <img [ngSrc]="game.image_url" width="460" height="215">
                </div>
            </a>
        </div>
<!--        <mat-paginator [length]="100"-->
<!--                       [pageSize]="pageSize"-->
<!--                       [pageSizeOptions]="pageSizeOptions"-->
<!--                       [pageIndex]="pageIndex"-->
<!--                       (page)="handlePageEvent($event)"-->
<!--                       aria-label="Select page">-->
<!--        </mat-paginator>-->
    </div>
</div>
