import {AfterViewInit, Component} from '@angular/core';
import {UserService} from "./user.service";
import {User} from "./interfaces/response";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
    public initLoading = true;
    constructor(private userService: UserService) {

    }

    ngAfterViewInit(){
        this.userService.getAuth().subscribe(response=>{
            if (response['authenticated'] === false){
                this.userService.loggedIn.next(false);
            }
            else {
                this.userService.user = new User(response)
                this.userService.loggedIn.next(true);
            }
            this.initLoading = false
        })
    }


}
